<template>
  <div class="comContent-container">
    <form-panel ref="formPanel"
                :form="form"
                v-bind='submitConfig'
                :submitUrl="submitUrl"
                submitText="保存"
                :submitBefore="submitBefore">
      <col2-detail>
        <col2-block title="巡更内容信息">
          <template v-if="!isConmunityUser">
            <el-form-item prop="regionId"
                          v-if="!$route.query.id"
                          label="所属公司"
                          :rules="[{ required: true, message: '请选择所属公司', trigger: 'change' }]">
              <v-select2 v-model="form.regionId"
                         placeholder="所属公司"
                         v-bind="regionIdParam"
                         @onChange="onRegionIdChange" />
            </el-form-item>
            <el-form-item v-else
                          label="所属公司">
              <span>{{ regionName }}</span>
            </el-form-item>
          </template>
          <el-form-item label="一级分类">
            <v-select v-if="!$route.query.id"
                      v-model="form.parentCategory"
                      :options="firstOps"
                      @change="getSecondOpsFun"></v-select>
            <span v-else>{{ parentName }}</span>
          </el-form-item>
          <el-form-item label="二级分类"
                        :rules="[{ required: !$route.query.id, message: '请选择二级分类', trigger: 'change' }]"
                        prop="categoryId">
            <v-select v-if="!$route.query.id"
                      v-model="form.categoryId"
                      :options="secondOps"></v-select>
            <span v-else>{{ name }}</span>
          </el-form-item>
          <div class="contentList">
            <el-form-item :label="index === 0 ? '巡更内容' : ''"
                          v-for="(item,index) in form.contents"
                          :key="index"
                          :rules="[{ required:true, message: '请输入巡更内容', trigger: 'blur' }]"
                          :prop="`contents.${index}.content`">
              <v-input v-model="item.content"
                       :class='index > 0 ? "contengLine":""'
                       :maxlength="300"
                       :width="200"></v-input>
              <v-button type="danger"
                        text="删除"
                        @click="delContent(index)"
                        style="margin-left:10px"
                        v-show="index > 0"></v-button>
            </el-form-item>
            <v-button text="新增"
                      @click='addContent'
                      class='addBtn'></v-button>
          </div>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { getContentUrl, saveContentUrl } from './api'
import { regionParams } from 'common/select2Params'
import { getFirstOpts, getSecondOps } from './../map'
import { Col2Block, Col2Detail } from 'components/bussiness'
export default {
  name: 'comContentForm',
  components: {
    Col2Block,
    Col2Detail
  },
  data () {
    return {
      submitUrl: saveContentUrl,
      submitConfig: {
        submitContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
        submitMethod: this.$route.query.id ? 'put' : 'post'
      },
      regionIdParam: regionParams,
      form: {
        parentCategory: undefined,
        categoryId: undefined,
        regionId: '',
        contents: [{ content: '' }, { content: '' }, { content: '' }, { content: '' }]
      },
      parentName: '',
      name: '',
      regionName: '',
      firstOps: [],
      secondOps: []
    }
  },
  computed: {
    isConmunityUser () {
      const userType = String(this.$store.state.userInfo.userType)
      return userType === '101' || userType === '102'
    }
  },
  // async created () {
  //   if (!this.$route.query.id) {
  //     this.firstOps = await getFirstOpts()
  //     if (this.firstOps.length > 0) {
  //       this.form.parentCategory = this.firstOps[0].value
  //       this.getSecondOpsFun(this.form.parentCategory)
  //     } else {
  //       this.$alert('请先添加巡更分类')
  //     }
  //   }
  // },
  mounted () {
    if (this.$route.query.id) {
      this.getDetailData(this.$route.query.id)
    } else {
      this.form.regionId = this.$store.state.userInfo.regionId
      this.onRegionIdChange()
    }
  },
  methods: {
    // 获取二级分类
    getSecondOpsFun (value) {
      this.secondOps = []
      getSecondOps(value, this.secondOps)
    },
    // 添加巡更内容
    addContent () {
      this.form.contents.push({ content: '' }, { content: '' }, { content: '' })
    },
    // 删除巡更内容
    delContent (index) {
      this.form.contents.splice(index, 1)
    },
    // 获取详情数据
    getDetailData (id) {
      let _this_ = this
      this.$axios.get(getContentUrl + id).then(res => {
        if (res.status === 100) {
          let rdata = res.data
          if (rdata) {
            _this_.parentName = rdata.parentName
            _this_.name = rdata.name
            _this_.regionName = rdata.regionName
            _this_.form.categoryId = rdata.id
            if (rdata.contents.length) {
              let contents = []
              rdata.contents.forEach(item => {
                contents.push({
                  content: item
                })
              })
              _this_.form.contents = contents
            }
          }
        }
      })
    },
    // 保存前数据处理
    submitBefore (data) {
      let contentsData = ''
      if (data.contents.length > 0) {
        data.contents.forEach(function (e) {
          contentsData += e.content + ','
        })
      }
      data.contents = contentsData.toString()
      data.dataObject = contentsData
      delete data.parentCategory
      console.log(data)
      return true
    },
    async onRegionIdChange () {
      this.firstOps = await getFirstOpts({ regionId: this.form.regionId })
      this.form.parentCategory = undefined // 置空
    }
  }
}
</script>
<style lang="scss" scoped>
.comContent-container {
  .cell-title {
    text-align: left;
    padding-left: 10px;
    margin: 15px 0;
    display: flex;
    align-items: center;
    .pre-point {
      // display: inline-block;
      border-radius: 50%;
      background-color: #dce2e7;
      height: 8px;
      width: 8px;
      margin-right: 5px;
    }
  }
  .contentList {
    .addBtn {
      margin-left: 168px;
    }
  }
}
</style>
