var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comContent-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitUrl: _vm.submitUrl,
              submitText: "保存",
              submitBefore: _vm.submitBefore,
            },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "巡更内容信息" } },
                [
                  !_vm.isConmunityUser
                    ? [
                        !_vm.$route.query.id
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "regionId",
                                  label: "所属公司",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请选择所属公司",
                                      trigger: "change",
                                    },
                                  ],
                                },
                              },
                              [
                                _c(
                                  "v-select2",
                                  _vm._b(
                                    {
                                      attrs: { placeholder: "所属公司" },
                                      on: { onChange: _vm.onRegionIdChange },
                                      model: {
                                        value: _vm.form.regionId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "regionId", $$v)
                                        },
                                        expression: "form.regionId",
                                      },
                                    },
                                    "v-select2",
                                    _vm.regionIdParam,
                                    false
                                  )
                                ),
                              ],
                              1
                            )
                          : _c(
                              "el-form-item",
                              { attrs: { label: "所属公司" } },
                              [_c("span", [_vm._v(_vm._s(_vm.regionName))])]
                            ),
                      ]
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "一级分类" } },
                    [
                      !_vm.$route.query.id
                        ? _c("v-select", {
                            attrs: { options: _vm.firstOps },
                            on: { change: _vm.getSecondOpsFun },
                            model: {
                              value: _vm.form.parentCategory,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "parentCategory", $$v)
                              },
                              expression: "form.parentCategory",
                            },
                          })
                        : _c("span", [_vm._v(_vm._s(_vm.parentName))]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "二级分类",
                        rules: [
                          {
                            required: !_vm.$route.query.id,
                            message: "请选择二级分类",
                            trigger: "change",
                          },
                        ],
                        prop: "categoryId",
                      },
                    },
                    [
                      !_vm.$route.query.id
                        ? _c("v-select", {
                            attrs: { options: _vm.secondOps },
                            model: {
                              value: _vm.form.categoryId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "categoryId", $$v)
                              },
                              expression: "form.categoryId",
                            },
                          })
                        : _c("span", [_vm._v(_vm._s(_vm.name))]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "contentList" },
                    [
                      _vm._l(_vm.form.contents, function (item, index) {
                        return _c(
                          "el-form-item",
                          {
                            key: index,
                            attrs: {
                              label: index === 0 ? "巡更内容" : "",
                              rules: [
                                {
                                  required: true,
                                  message: "请输入巡更内容",
                                  trigger: "blur",
                                },
                              ],
                              prop: `contents.${index}.content`,
                            },
                          },
                          [
                            _c("v-input", {
                              class: index > 0 ? "contengLine" : "",
                              attrs: { maxlength: 300, width: 200 },
                              model: {
                                value: item.content,
                                callback: function ($$v) {
                                  _vm.$set(item, "content", $$v)
                                },
                                expression: "item.content",
                              },
                            }),
                            _c("v-button", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: index > 0,
                                  expression: "index > 0",
                                },
                              ],
                              staticStyle: { "margin-left": "10px" },
                              attrs: { type: "danger", text: "删除" },
                              on: {
                                click: function ($event) {
                                  return _vm.delContent(index)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      _c("v-button", {
                        staticClass: "addBtn",
                        attrs: { text: "新增" },
                        on: { click: _vm.addContent },
                      }),
                    ],
                    2
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }