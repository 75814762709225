// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}electronicPatrol/content`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}electronicPatrol/content/export`
// 删除通用内容
const delContentUrl = `${API_CONFIG.butlerBaseURL}electronicPatrol/content/`
// 保存巡更内容
const saveContentUrl = `${API_CONFIG.butlerBaseURL}electronicPatrol/content`
// 获取巡更内容详情
const getContentUrl = `${API_CONFIG.butlerBaseURL}electronicPatrol/content/`

export {
  getListURL,
  exportListURL,
  delContentUrl,
  saveContentUrl,
  getContentUrl
}
